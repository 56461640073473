import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './styles/a.css'
import wow from "wowjs"

Vue.config.productionTip = false

Vue.prototype.$wow = wow

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
