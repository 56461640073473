import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/UCL360',
    name: 'UCL360',
    component: () => import(/* webpackChunkName: "UCL360" */ '../views/UCL360.vue')
  },
  
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },

  {
    path: '/ScanToBIM',
    name: 'ScanToBIM',
    component: () => import(/* webpackChunkName: "ScanToBIM" */ '../views/ScanToBIM.vue')
  },
  
  {
    path: '/Solution',
    name: 'Solution',
    component: () => import(/* webpackChunkName: "Solution" */ '../views/Solution.vue')
  },
  {
    path: '/Case',
    name: 'Case',
    component: () => import(/* webpackChunkName: "Case" */ '../views/Case.vue')
  },
  
  {
    path: '/About',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
  },

  {
    path: '/News',
    name: 'News',
    component: () => import(/* webpackChunkName: "News" */ '../views/News.vue')
  },
  
  {
    path: '/Info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "Info" */ '../views/Info.vue')
  },
  
  
  {
    path: '/digitalTwin',
    name: 'digitalTwin',
    component: () => import(/* webpackChunkName: "digitalTwin" */ '../views/digitalTwin.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
