<template>
  <div class="footer_component" :class="{ white: white }">
    <div class="m_top_content" v-if="isMobile">
      <img class="logo" src="@/assets/logo_h.png" alt="" srcset="" />
      <div class="adress">
        <h2>公司地址</h2>
        <ul>
          <li>成都地址：成都市高新区孵化园九号楼D座一楼</li>
          <li>香港地址：新界沙田香港科学园3期16W大厦309室</li>
          <li>深圳地址：深圳市南山区科技园科研路9号比克科技大厦1101G</li>
          <li>上海地址：上海市浦东区秀浦路2555号漕河泾康桥商务绿洲A8栋706室</li>
        </ul>
      </div>
      <div class="line">
        <div class="contact">
          <h2>联系我们</h2>
          <ul>
            <li>
              商业合作：<a href="mailto:business@unre.com">business@unre.com</a>
            </li>
            <li>
              媒体合作：<a href="mailto:media@unre.com">media@unre.com </a>
            </li>
            <li>
              供应商自荐：<a href="supplier@unre.com">supplier@unre.com</a>
            </li>
            <li>电话号码：<a href="tel:0755-26401645">0755-26401645</a></li>
          </ul>
        </div>

        <div class="focus">
          <h2>关注我们</h2>
          <div class="qrcode_wrap">
            <div class="qrcode_item">
              <img src="@/assets/qr1.png" alt="" />
              <p>官方微信公众号微信扫码关注</p>
            </div>
            <div class="qrcode_item">
              <img src="@/assets/qr2.png" alt="" />
              <p>官方抖音号欢迎扫码关注</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top_content" v-else>
      <img src="@/assets/logo.png" alt="" srcset="" v-show="!white" />
      <img src="@/assets/logo_h.png" alt="" srcset="" v-show="white" />
      <div class="adress">
        <h2>公司地址</h2>
        <ul>
          <li>成都地址：成都市高新区孵化园九号楼D座一楼</li>
          <li>香港地址：新界沙田香港科学园3期16W大厦309室</li>
          <li>深圳地址：深圳市南山区科技园科研路9号比克科技大厦1101G</li>
          <li>上海地址：上海市浦东区秀浦路2555号漕河泾康桥商务绿洲A8栋706室</li>
          <li>杭州地址：浙江省杭州市富阳区春江街道春亭路18号东区3号楼3层</li>
        </ul>
      </div>

      <div class="contact">
        <h2>联系我们</h2>
        <ul>
          <li>
            商业合作：<a href="mailto:business@unre.com">business@unre.com</a>
          </li>
          <li>媒体合作：<a href="mailto:media@unre.com">media@unre.com </a></li>
          <li>供应商自荐：<a href="supplier@unre.com">supplier@unre.com</a></li>
          <li>电话号码：<a href="tel:0755-26401645">0755-26401645</a></li>
        </ul>
      </div>

      <div class="focus">
        <h2>关注我们</h2>
        <div class="qrcode_wrap">
          <div class="qrcode_item">
            <img src="@/assets/qr1.png" alt="" />
            <p>官方微信公众号</p>
            <p>微信扫码关注</p>
          </div>
          <div class="qrcode_item">
            <img src="@/assets/qr2.png" alt="" />
            <p>官方抖音号</p>
            <p>欢迎扫码关注</p>
          </div>
        </div>
      </div>
    </div>

    <div class="m_bottom_content" v-if="isMobile">
      <p>盎锐（杭州）信息科技有限公司</p>
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >网站备案/许可证号：浙ICP备2024069476号-1</a
      >
      <p>浙公网安备：33018302001732</p>
    </div>
    <div class="bottom_content" v-else>
      <p>盎锐（杭州）信息科技有限公司</p>
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >网站备案/许可证号：浙ICP备2024069476号-1</a
      >
      <p>浙公网安备：33018302001732</p>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils";
export default {
  name: "foot",
  props: {
    msg: String,
  },
  data() {
    return {
      isMobile: false,
      white: false,
    };
  },
  watch: {
    $route: function () {
      if (this.$route.name === "Home" || this.$route.name === "UCL360") {
        this.white = false;
      } else {
        this.white = true;
      }
    },
  },
  components: {},
  created() {
    this.isMobile = isMobile();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.footer_component {
  background: #3f4d59;
  &.white {
    background: #ffffff;

    .top_content {
      border-bottom: 1px solid #efefef;
      .qrcode_wrap {
        color: #666666;
      }
      li {
        color: #666666;
        a {
          color: #666666;
        }
      }
      h2 {
        color: #4d4d4d;
      }
    }
  }
  .bottom_content {
    padding: 51px 0;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #999ea2;
    p {
      margin: 0 70px;
    }
    a {
      color: #999ea2;
      text-decoration: none;
    }
  }

  .top_content {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0.27rem 1rem;
    border-bottom: 1px solid #65717a;
    li {
      list-style-type: none;

      // line-height: 0.16rem;
      color: #cccccc;
      margin-bottom: 0.11rem;
      font-size: 0.07rem;
    }
    img {
      width: 118px;
      height: 39px;
    }
    h2 {
      margin-bottom: 0.12rem;
      color: #fcfcfc;
      font-size: 24px;
    }
    a {
      color: #cccccc;
      text-decoration: none;
      &:hover {
        color: #1e80ff;
      }
    }
    .focus {
      h2 {
        text-align: center;
      }
    }
    .qrcode_wrap {
      display: flex;

      font-size: 0.07rem;
      color: #cccccc;
      .qrcode_item {
        width: 0.8rem;
        text-align: center;
        // margin-right: 0.17rem;
        p {
          margin-bottom: 0.02rem;
        }
      }
      img {
        width: 0.44rem;
        height: 0.44rem;
        margin-bottom: 0.05rem;
      }
    }
  }
  .m_top_content {
    padding: 1.47rem 0.43rem 0.43rem;
    background: #00274a;
    text-align: left;
    color: #fff;

    border: 0.01rem solid rgba(252, 252, 252, 0.2);
    .logo {
      margin-bottom: 0.85rem;
    }
    .adress {
      margin-bottom: 0.72rem;
    }
    li {
      list-style-type: none;

      line-height: 0.7rem;
      color: #cccccc;

      font-size: 0.29rem;
    }

    h2 {
      margin-bottom: 0.37rem;
      color: #fcfcfc;

      font-size: 0.37rem;
    }
    a {
      color: #cccccc;
      text-decoration: none;
      &:hover {
        color: #1e80ff;
      }
    }
    .line {
      display: flex;
      justify-content: space-between;
    }
    .focus {
      h2 {
        text-align: center;
      }
    }
    .qrcode_wrap {
      display: flex;

      line-height: 0.4rem;

      font-size: 12px;
      color: #cccccc;
      .qrcode_item {
        width: 2.2rem;
        text-align: center;
        p {
          font-size: 12px;
          margin: 0.05rem 0;
        }
      }
      img {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
  .m_bottom_content {
    background: #00274a;
    padding: 1.13rem 0 1.68rem;

    line-height: 0.4rem;
    font-size: 0.27rem;
    color: #cccccc;
    p {
      margin-bottom: 0.24rem;
    }
    a {
      color: #999ea2;
      text-decoration: none;
    }
  }
}
</style>
