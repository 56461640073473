<template>
  <div id="app">
    <!-- <div id="nav" class="fadeInRight">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Menu></Menu>
    <router-view/>

    <!-- <div class="test fadeInLeft" v-if="show1">123</div> -->
    <!-- <div class="test fadeInLeft" v-if="show2">123</div>
    <div class="test fadeInLeft" v-if="show3">123</div> -->
    <Footer></Footer>
  </div>
</template>


<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      mobile:false
    }
  },
  components: {
    Menu,
    Footer
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScrollbox, true)
    // var that = this
    // setTimeout(() => {
    //   that.show1 = true
    // }, 2000);

     
  },
  methods: {
  //   handleScrollbox() {
  //     this.currentScroll = window.pageYOffset //表示当前滚动的位置

  //     if (this.currentScroll >= this.$refs.testref.offsetTop - 600) {
  //       //当前滚动位置到达testref的时候，显示div（100作为调整用）
  //       this.testShow = true
  //     }
  //     if (this.currentScroll >= this.$refs.test2ref.offsetTop - 440) {
  //       this.testShow2 = true
  //     }
  //     if (this.currentScroll >= this.$refs.test3ref.offsetTop - 440) {
  //       this.testShow3 = true
  //     }

  //     if (this.currentScroll >= this.$refs.test4ref.offsetTop - 440) {
  //       this.testShow4 = true
  //     }
  //   },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollbox, true)
  },
}
</script>

<style lang="less">
// @import "./style/header.less";



html{
  height: 100%;
  
}
// @media screen and (max-width: 1300px) {
//   min-width: 1300px;
// }
body{
  min-height: 100%;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  color: #333;
  font-size: 14px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
}

.breadcrumb_wrap {
    display: flex;
    align-items: center;
    padding: 0.21rem 1.1rem;
  background: #fff;
    color: #666666;
    span {
      color: #02aeec;
    }
    .home_icon {
      margin-right: 0.05rem;
    }
    .right_icon {
      width: 18px;
      transform: rotate(90deg);
    }
  }

</style>
